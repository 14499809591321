var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('CRow',{staticClass:"mb-0"},[_c('CCol',{attrs:{"lg":"3"}},[_c('CInput',{attrs:{"placeholder":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"setting_name","options":_vm.personnelTypeList.data,"reduce":function (item) { return item.id; },"placeholder":"Personnel Type"},model:{value:(_vm.personnel_type),callback:function ($$v) {_vm.personnel_type=$$v},expression:"personnel_type"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"setting_name","options":_vm.depotList.data,"reduce":function (item) { return item.id; },"placeholder":"Depot"},model:{value:(_vm.depot_id),callback:function ($$v) {_vm.depot_id=$$v},expression:"depot_id"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"label","options":[
            
            { 
              value: 'direct_hire', 
              label: 'Direct Hire'
            },
            { 
              value: 'outsourced', 
              label: 'Outsourced'
            }
          ],"reduce":function (item) { return item.value; },"placeholder":"Employment Type"},model:{value:(_vm.employment_type),callback:function ($$v) {_vm.employment_type=$$v},expression:"employment_type"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('CInput',{attrs:{"placeholder":"Contact"},model:{value:(_vm.contact_no),callback:function ($$v) {_vm.contact_no=$$v},expression:"contact_no"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"vendor_name","options":_vm.vendorList.data,"reduce":function (item) { return item.id; },"placeholder":"Vendor"},model:{value:(_vm.vendor_id),callback:function ($$v) {_vm.vendor_id=$$v},expression:"vendor_id"}})],1),_c('CCol',{staticClass:"float-right mt-1",attrs:{"lg":"3"}},[_c('CButton',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"info","variant":"outline"},on:{"click":_vm.search}},[_vm._v("Search")]),_c('CButton',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"info","variant":"outline"},on:{"click":_vm.clearSearch}},[_vm._v("Clear Filter")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }