<template>
<div>
    <CCard class="p-2">
			<form @submit.prevent="submit">
                <CCardHeader>
					<CRow style="color: #1352a1;">
				
						<CCol lg="8">
							<h5 v-if="!editMode">New Personnel</h5>
							<h5 v-else>Update Personnel</h5>
						</CCol>
				     	<CCol lg="4">
				      		<div style="text-align: right">
				      			<CButton size="sm" class="btn btn-ghost-dark" @click="backToTable()"> Cancel</CButton> &nbsp; 
					      		<CButton class="px-3"  size="sm" color="info" type="submit"><i class="fa fa-save"></i> Save</CButton>
				      		</div>
						</CCol>
					</CRow>
				</CCardHeader>
				
                <CCardBody class="card-form">
                    <CCard class="pl-3 pr-3">
                        
                        <CRow class="mt-3">
                            <CCol lg="12">
                                <strong class="p-1 mb-2 bg-secondary text-dark" 
									style="font-size:12px; border-radius: 5px;"> Personnel Information
							</strong>
                            </CCol>
                        </CRow>
                        <br/> 
                        <CRow>
                            <CCol lg="4" class="mb-2">
                                <label>Last Name <span class="text-danger">*</span></label>	
                                <input
                                class="mb-0 text-uppercase form-control"
                                v-model="dataParams.last_name"
                                required="true"  
                                />	
                                <!-- <small class="text-info">
                                    Name should be in CAPITAL LETTERS. And the format should be (LAST NAME, FIRSTNAME MIDDLE NAME SUFFIX. Ex. RAMISO, APRIL R.)
                                </small> -->
                            </CCol>
                            <CCol lg="4" class="mb-2">
                                <label>First Name <span class="text-danger">*</span></label>	
                                <input
                                class="mb-0 text-uppercase form-control"
                                v-model="dataParams.first_name"
                                required="true"  
                                />	
                            </CCol>
                            <CCol lg="4" class="mb-2">
                                <label>Middle Name</label>	
                                <input
                                class="mb-0 text-uppercase form-control"
                                v-model="dataParams.middle_name" 
                                />	
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                                <CInput
                                label="Home Address"
                                v-model="dataParams.home_address"  
                                />
                            </CCol> 
                        </CRow>
                        <CRow>
                            <CCol lg="6">
                                <label>Contact Number </label>
                                <CInput
                                v-model="dataParams.contact_no"
                                type="number"
                                />
                            </CCol>
                            <CCol lg="6">
                                <label>Email Address </label>
                                <CInput
                                label=""
                                
                                v-model="dataParams.email"
                                type="email"
                                />
                            </CCol>
                            
                        </CRow>
                        <CRow>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Birthdate</label>
                                    <Datepicker 
                                        input-class="form-control bg-white" 
                                        v-model="dataParams.birthday"
                                        
                                        :config="options"
                                    >
                                    </Datepicker> 
                                </div> 
                            </CCol>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label for="gender">Gender</label>
                                    <CInputRadioGroup  
                                        :options="[
                                            { 
                                                value: 'male', 
                                                label: 'Male'
                                            },
                                            { 
                                                value: 'female', 
                                                label: 'Female'
                                            }
                                        ]" 
                                        :checked="dataParams.gender"
                                        @update:checked="updateGender"
                                        :inline="true"
                    
                                        
                                    />
                                    
                                </div>
                            </CCol>
                            
                        </CRow>
                        <CRow>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">License Expiry Date</label>
                                    <Datepicker 
                                        input-class="form-control bg-white" 
                                        v-model="dataParams.license_expiry"
                                        required="true"
                                        :config="options"
                                    >
                                    </Datepicker> 
                                </div> 
                            </CCol>
                            <CCol lg="6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Status <span class="text-danger">*</span></label> 
                                    <CInputRadioGroup  
                                        :options="[
                                            { 
                                                value: 'active', 
                                                label: 'Active'
                                            },
                                            { 
                                                value: 'inactive', 
                                                label: 'Inactive'
                                            }
                                        ]" 
                                        :checked="dataParams.status"
                                        @update:checked="updateStatus"
                                        :inline="true" 
                                    />
                                </div>
                            </CCol>
                        </CRow>
                    </CCard>
                    <CCard class="pl-3 pr-3">
                    
                        <CRow class="mt-3">
                            <CCol lg="12">
                                <strong class="p-1 mb-2 bg-secondary text-dark" 
									style="font-size:12px; border-radius: 5px;">  Employment Details
							</strong>
                            </CCol>
                        </CRow>
                            <br/>
                            <CRow>
                                <CCol lg="6">
                                    <div class="form-group">
                                        <label>Personnel Type <span class="text-danger">*</span></label>
                                        <v-select 
                                            label="setting_name" 
                                            :options="personnelTypeList.data"
                                            :reduce="item => item.id"
                                            v-model="dataParams.personnel_type_name"
                                            placeholder="-Select-"
                                        >
                                            <template #search="{attributes, events}">
                                                <input
                                                    class="vs__search"
                                                    :required="!dataParams.personnel_type_name"
                                                    v-bind="attributes"
                                                    v-on="events"
                                                />
                                            </template>
                                        </v-select>
                                    </div> 
                                </CCol>
                                <CCol lg="6">
                                    <div class="form-group">
                                        <label for="employment_type">Employment Type <span class="text-danger">*</span></label>
                                        <CInputRadioGroup  
                                            :options="[
                                                { 
                                                    value: 'direct_hire', 
                                                    label: 'Direct Hire'
                                                },
                                                { 
                                                    value: 'outsourced', 
                                                    label: 'Outsourced'
                                                }
                                            ]" 
                                            :checked="dataParams.employment_type"
                                            @update:checked="updateEmploymentType"
                                            :inline="true" 
                                            required="true"
                                        />
                                    </div>
                                </CCol>
                            </CRow>
                            <CRow v-if="dataParams.employment_type == 'outsourced'">
                                <CCol lg="12">
								<div class="form-group" v-if="!dataParams.company_owned"> 
									<label class="mb-1">Vendor</label>
									<v-select 
									
										:options="vendorList.data"
										:reduce="item => item.id"
										v-model="dataParams.vendor_id"
										placeholder="-Select-" 
                                        :required="dataParams.employment_type == 'outsourced'"
									> 
									</v-select>
								</div>
							</CCol> 
                                <!-- <CCol lg="12">
                                    <label>Vendor <span class="text-danger">*</span></label>
                                    <CSelect
                                        
                                        :options="vendorList.data" 
                                        :value="dataParams.vendor_id"
                                        placeholder="-Select-"
                                        :required="dataParams.employment_type == 'outsourced'"
                                        @update:value="updateVendor"
                                    />
                                </CCol> -->
                            </CRow> 
                            <CRow>
                                <CCol lg="6">
                                    <CInput
                                    label="Employee ID"
                                    v-model="dataParams.employee_id" 
                                    />
                                </CCol>
                                <CCol lg="6"> 
                                    <div class="form-group"> 
                                        <label>Depot <span class="text-danger">*</span></label>
                                        <v-select 
                                            :disabled="depot_id != null"
                                            label="setting_name" 
                                            :options="depotList.data"
                                            :reduce="item => item.id"
                                            v-model="dataParams.depot_id"
                                            placeholder="-Select-"
                                        >
                                            <template #search="{attributes, events}">
                                                <input
                                                    class="vs__search"
                                                    :required="!dataParams.depot_id"
                                                    v-bind="attributes"
                                                    v-on="events"
                                                />
                                            </template>
                                        </v-select>
                                    </div>  
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date Started</label>
                                        <Datepicker 
                                            input-class="form-control bg-white" 
                                            v-model="dataParams.date_started"
                                            
                                            :config="options"
                                        >
                                        </Datepicker> 
                                    </div> 
                                </CCol>
                                <CCol lg="6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Date of Separation <span class="text-danger" v-if="dataParams.status == 'inactive'">*</span></label>
                                        <Datepicker 
                                            input-class="form-control bg-white" 
                                            v-model="dataParams.date_seperation"
                                            
                                            :config="options" 
                                            
                                        >
                                        </Datepicker> 
                                        <!-- <input type="text" v-model="dataParams.date_seperation" class="form-control" style="margin-top: -35px;" :required="dataParams.status == 'inactive'"> -->
                                    </div> 
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <label>Reason of Separation <span class="text-danger" v-if="dataParams.status == 'inactive'">*</span></label>
                                    <CInput
                                    label=""
                                    
                                    v-model="dataParams.reason_of_separation" 
                                    :required="dataParams.status == 'inactive'"
                                    />
                                </CCol>
                            </CRow>  
                            <CRow>
                                <CCol lg="6">
                                    <CInput
                                    label="Bank Account"
                                    
                                    v-model="dataParams.bank_account" 
                                    type="number"
                                    />
                                </CCol>
                                <CCol lg="6">
                                    <CInput
                                    label="Daily Rate"
                                    
                                    v-model="dataParams.daily_rate" 
                                    @keypress="isNumber($event)"
                                    />
                                </CCol>
                            </CRow> 
                            <CRow>
                                <CCol lg="12">
                                    <CInput
                                    label="Remarks"
                                    
                                    v-model="dataParams.remarks" 
                                    />
                                </CCol> 
                            </CRow> 
                    </CCard>   
                    <CCard class="pl-3 pr-3">
                        <CRow class="mt-3">
                            <CCol lg="12">
                                <strong class="p-1 mb-2 bg-secondary text-dark" 
									style="font-size:12px; border-radius: 5px;">Benefits
							</strong>
                            </CCol>
                        </CRow>
                    <br/>
                            <CRow>
                                <CCol lg="6">
                                    <label>Philhealth No. <span class="text-danger" v-if="is_roadwise()">*</span></label>
                                    <CInput
                                    v-model="dataParams.philhealth_no" 
                                    :required="is_roadwise()"  
                                    
                                    />
                                </CCol>
                                <CCol lg="6">
                                    <label>SSS No. <span class="text-danger" v-if="is_roadwise()">*</span></label>
                                    <CInput
                                    v-model="dataParams.sss_no" 
                                    :required="is_roadwise()"  
                                    />
                                </CCol>
                            </CRow> 
                            <CRow>
                                <CCol lg="6">
                                    <label>TIN No. <span class="text-danger" v-if="is_roadwise()">*</span></label>
                                    <CInput
                                        v-model="dataParams.tin_no" 
                                        :required="is_roadwise()"  
                                    />
                                </CCol>
                                <CCol lg="6">
                                    <label>Pag-IBIG No. <span class="text-danger" v-if="is_roadwise()">*</span></label>
                                    <CInput
                                    v-model="dataParams.pag_ibig_no" 
                                    :required="is_roadwise()"  
                                    
                                    />
                                </CCol>
                            </CRow>
                    </CCard>           
                    <CCard class="pl-3 pr-3">
                        <CRow class="mt-3">
                            <CCol lg="12">
                                <strong class="p-1 mb-2 bg-secondary text-dark" 
									style="font-size:12px; border-radius: 5px;"> Emergency Contact
							</strong>
                            </CCol>
                        </CRow>
                    <br/>
                        
                            <CRow>
                                <CCol lg="6">
                                    <label>Contact Person Name <span class="text-danger" v-if="is_roadwise()">*</span> </label>
                                    <CInput
                                        v-model="dataParams.emergency_name" 
                                        :required="is_roadwise()"  
                                    
                                    />
                                </CCol>
                                <CCol lg="6">
                                    <label>Contact Number <span class="text-danger" v-if="is_roadwise()">*</span></label>
                                    <CInput
                                        v-model="dataParams.emergency_contact_no" 
                                        type="number"
                                        :required="is_roadwise()"  
                                    
                                    />
                                </CCol>
                            </CRow> 
                            
                    </CCard>    
                </CCardBody>
			</form>
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../../style.css';
import moment from 'moment';

export default {
	mounted(){  
		this.getVendor();
		this.getDepot();
		this.getPersonnelType();

		if(this.$route.params.id != undefined){
			this.editMode = true;
		} 
		if(this.$route.params.id){
			this.getData();
		} 

	},
	data(){
		return{
			config,
			options:'',
			title:'',
			isLoading: false,
			formModal: false,
			showViewModal: false,
			dataListAll: [],
			dataList: [],
			dataListInactive: [],
			current_tab: "All",
			count: 0,
			depotName: "",
			depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id,
			dataParams: {
				last_name: "",
				first_name: "",
				middle_name: "",
				contact_no: "",
                license_expiry:"",
				email: "",
				birthday: "",
				home_address: "",
				gender: "",
				personnel_type_name: "",
				employment_type: "direct_hire",
				vendor_id: "",
				employee_id: "",
				depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
				date_started: "",
				date_seperation: "",
				philhealth_no: "",
				sss_no: "",
				status: "active",
				remarks: "",
				emergency_name: "",
				emergency_contact_no: "",
				bank_account: "",
				daily_rate: "",
				tin_no: "",
				pag_ibig_no: "",
				reason_of_separation: "",

			},
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			vendorList: {
				data: []
			},
			depotList: {
				data:[]
			},
			personnelTypeList: {
				data: []
			},
			setting_type : ''
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: { 
		 backToTable(){
	    	this.$router.push('/data/personnel')
	    },
    watch: {
		'dataParams.name' : function(val) {
			this.dataParams.name = val.toUpperCase()
		}
	},

		getData(){
            this.$showLoading(true)

			axios.get(config.api_path+"/personnel/"+this.$route.params.id)
			.then(response=>{
                this.$showLoading(false)
				this.dataParams = response.data.data;

				// this.dataParams.commodity_type_id = (this.dataParams.commodity_type_id) ? parseInt(this.dataParams.commodity_type_id) : this.dataParams.commodity_type_id;
				// this.dataParams.location = this.dataParams.location ? parseInt(this.dataParams.location) : this.dataParams.location;
			})
			.catch(err => {
			this.$showLoading(false)
		}) 

		}, 

        submit(){
            this.dataParams.name = this.$options.filters.name_concat(this.dataParams.last_name?.trim(), this.dataParams.first_name?.trim(), this.dataParams.middle_name?.trim());
            this.dataParams.last_name = this.dataParams.last_name?.trim();
            this.dataParams.first_name = this.dataParams.first_name?.trim();
            this.dataParams.middle_name = this.dataParams.middle_name?.trim();
	    	var ax = {};
            // this.$set(this.dataParams,'name',this.dataParams.name?.toUpperCase())
	    	if(this.dataParams.vendor_id == ""){
	    		this.dataParams.vendor_id = null;
	    	}

			if(this.dataParams.employment_type == 'direct_hire') {
				this.dataParams.vendor_id = null;
			}

            // if(this.dataParams.personnel_type_name) {
            //     this.dataParams.personnel_type = this.personnelTypeList.data.filter(item => item.setting_name == this.dataParams.personnel_type_name)[0]?.id;   
            // }

            this.dataParams.personnel_type = (typeof this.dataParams.personnel_type_name) == 'object' ?  this.dataParams.personnel_type_name?.id : this.dataParams.personnel_type_name;

	    	if(this.dataParams.employee_id == ""){
	    		this.dataParams.employee_id = null;
	    	}  

	    	if(this.dataParams.birthday == "" || this.dataParams.birthday == null){
	    		this.dataParams.birthday = null;
	    	}
	    	else{
	    		this.dataParams.birthday = moment(this.dataParams.birthday).format('YYYY-MM-DD');
	    	}

	    	if(this.dataParams.date_seperation == "" || this.dataParams.date_seperation == null){
	    		this.dataParams.date_seperation = null;
	    	}
	    	else{
	    		this.dataParams.date_seperation = moment(this.dataParams.date_seperation).format('YYYY-MM-DD');
	    	}

	    	if(this.dataParams.date_started == "" || this.dataParams.date_started == null){
	    		this.dataParams.date_started = null;
	    	}
	    	else{
	    		this.dataParams.date_started = moment(this.dataParams.date_started).format('YYYY-MM-DD');
	    	}

			this.dataParams.daily_rate = (this.dataParams.daily_rate) ? this.dataParams.daily_rate : 0;

			this.dataParams.is_company_with_agency = config.global_user_data.company && config.global_user_data.company.company_type.toLowerCase() === 'trucking with agency' ? 1 : 0;
            
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/personnel/"+this.selectedRow.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/personnel", this.dataParams)
	    	}
            
	    	ax.then(response => {
				const { status } = response.data;
				
				if(status === 'duplicate') {
					Swal.fire({
						title: 'Personnel name already exists.',
						text,
						icon: 'error', 
					})
						return;
				}

	    		var text = 'Personnel successfully added!';
	    		if(this.editMode){
	    			text = 'Personnel successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					name: "",
					contact_no: "",
					email: "",
					birthday: "",
					home_address: "",
					gender: "",
					personnel_type: "",
					employment_type: "direct_hire",
					vendor_id: "",
					employee_id: "",
					depot_id: "",
					date_started: "",
					date_seperation: "",
					philhealth_no: "",
					sss_no: "",
					status: "active",
					remarks: "",
					emergency_name: "",
					emergency_contact_no: "",
					bank_account: "",
					daily_rate: "",
				}
	    		this.isLoading = false;
	    		this.$router.push('/data/view_personnel/'+response.data.data.id)
	    	})
	    	.catch(e=>{
	    		this.isLoading = false;
	    	})
	    },

        getVendor(){

            axios.get(config.api_path+'/vendor?page=1&limit=10000000')
            .then(response => {
                this.vendorList = response.data;
                this.vendorList.data = this.vendorList.data.map((value, index)=>{
                    value.value = value.id;
                    value.label = value.vendor_name;
                    return value;
                })

                
            })

            },
        getPersonnelType(){
                axios.get(config.api_path+'/reference/settings-list',{
                params:{
                    setting_type:'personnel_type'
                }
                    })
                .then(response => {
                this.personnelTypeList = response.data; 
                })

            },


         getDepot(){
                axios.get(config.api_path+'/reference/settings-list',{
                params:{
                    setting_type:'depot'
                }
                    })
                .then(response => {
                this.depotList = response.data; 
                })

                },
        updateVendorType(data){
	    	this.dataParams.vendor_type = data;
	    },

	    updatePersonnelType(data){
	    	this.dataParams.personnel_type = data;
	    },

	    updateEmploymentType(data){
			console.log(data)
	    	this.dataParams.employment_type = data;
	    },

	    updateVendor(data){
	    	this.dataParams.vendor_id = data;
	    },

	    updateDepot(data){
	    	this.dataParams.depot_id = data;
	    },

	    updateStatus(data){
	    	this.dataParams.status = data;
	    },
        updateGender(data){
	    	this.dataParams.gender = data;
	    },

	    updateCompanyOwned(e){
	    	this.dataParams.company_owned = e;
	    }

 	}
}
</script>
